import * as React from "react"

import Layout from "../components/Layout"
import "./404.scss"
import { PageSeo } from "../components/PageSeo"

const NotFoundPage = () => (
  <Layout>
    <div className="notfound">
      <div className="msg text-center">
        <h1 data-text="404: Not Found">404: Not Found</h1>
        <h6>You just hit a route that doesn&#39;t exist... the sadness.</h6>
      </div>
    </div>
  </Layout>
)

export const Head = () => <PageSeo title="404: Not Found" />

export default NotFoundPage
